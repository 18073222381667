html, body, #app {
}

body {
    background: #f3f6f8;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    height: auto;
}

#app {
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Mulish', sans-serif;
    letter-spacing: 1px;
    color: #000;
}
h2  {
    font-size: 16px;
}
h3  {
    font-size: 13px;
    opacity: 0.7;
}

.slideIn {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
    -webkit-animation-timing-function: cubic-bezier(.02, .41, .03, 1);
            animation-timing-function: cubic-bezier(.02, .41, .03, 1);
}

.fade-in {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
}

.zoom-in {
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-name: zoomIn;
            animation-name: zoomIn;
    -webkit-animation-timing-function: cubic-bezier(.02, .41, .03, 1);
            animation-timing-function: cubic-bezier(.02, .41, .03, 1);
}

.zoom-in-fast {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-name: zoomInSmall;
            animation-name: zoomInSmall;
    -webkit-animation-timing-function: cubic-bezier(.02, .41, .03, 1);
            animation-timing-function: cubic-bezier(.02, .41, .03, 1);
}

@-webkit-keyframes zoomInSmall {
    from {
        opacity: 0;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
                transform: scale3d(1.1, 1.1, 1.1);
    }

    70% {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(1.2, 1.2, 1.2);
                transform: scale3d(1.2, 1.2, 1.2);
    }

    70% {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
